import React from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
const { loadMpuser, enableOrDisabledMpuser, updateCurrentAppId, deleteMpuser, editMpuser } = iceStarkData.store.get('commonAction')?.mpUsers;
import { DataTable, Pager, Dialog, Textarea, FormGroupList, FormGroup } from 'tyb';
const {setNavBar} = iceStarkData.store.get('commonAction')?.ui;
import './mpUser.scss';
import { ConfirmPopout } from 'ucode-components';

@connect (
  state => {
    return {
      ...state.role,
      ...state.mpUsers,
      permissionIds: state.users.permissionIds||[],
    };
  },
  {
    setNavBar,
    updateCurrentAppId,
    loadMpuser: loadMpuser.REQUEST,
    enableOrDisabledMpuser: enableOrDisabledMpuser.REQUEST,
    deleteMpuser: deleteMpuser.REQUEST,
    editMpuser: editMpuser.REQUEST,
  }
)
class MpUsers extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      tableColumns: this.getTableColumns(),
      showRemarkDialog: false, 
      currentRecord: {
        remark: ''
      },
      remarkerror: { error: "", touched: null }
    };
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search) || '';
    this.props.updateCurrentAppId({ currentAppId: parsed.appId });
    this.props.loadMpuser();
    this.props.setNavBar('人员管理')
  }

  // table head
  getTableColumns = () => {
    const tableHeadArr = [
      {
        key: 'id',
        dataIndex: 'id',
        title: 'ID',
      },
      {
        key: 'addressBookName',
        dataIndex: 'addressBookName',
        title: '通讯录',
      },
      {
        key: 'nickName',
        dataIndex: 'nickName',
        title: '微信昵称',
      },

      {
        key: 'avatarUrl',
        dataIndex: 'avatarUrl',
        title: '微信头像',
        render: (value, record) => <div className="avatarUrl"><img src={ value} /></div>
      },
      {
        key: 'remark',
        dataIndex: 'remark',
        title: '备注',
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: '状态',
        render: (value, record) => (
          <div style={ { color: value == 'ENABLE' ? '#00C156' : 'nomal' } }>{ value == 'ENABLE' ? '使用中' : '已禁用'}</div>
        )
      },
      {
        key: 'action',
        dataIndex: 'action',
        title: '操作',
        render: (value, record) => (
          <div className="table-operate-btn" style={ { color: '#006EFF' } }>
            { this.props.permissionIds.includes('data.organization.mpuser.edit') && <span>  <a
              href="javascript:;"
              onClick={ () => { this.setState({ remarkerror: { error: '', touched: null }, currentRecord: { ...record, remark: record.remark ? record.remark : '' }, showRemarkDialog: true }); } }
            >备注 </a></span> }

            { this.props.permissionIds.includes('data.organization.mpuser.status') && (record.status == 'DISABLE') && <span className="btn-opration">
              <a
                href="javascript:;"
                onClick={ () => { this.props.enableOrDisabledMpuser({ id: record.id, status: 'ENABLE' }); this.setState({ visiableId: '' }); } }
              >启用 </a></span> }

            { this.props.permissionIds.includes('data.organization.mpuser.status') && (record.status == 'ENABLE') &&
              <span className="btn-opration" >
                <a
                  href="javascript:;"
                  onClick={ () => { this.setState({ visiableId: `${ record.id }disabled` }); } }
                > 禁用 </a>
                { (this.state.visiableId === `${ record.id }disabled`) &&
                  <ConfirmPopout
                    strongText={ '确认禁用该人员吗，禁用后不可使用小程序功能' }
                    text={ ' ' }
                    styles={ { right: 0, width: '360px' } }
                    onOk={ () => { this.props.enableOrDisabledMpuser({ id: record.id, status: 'DISABLE', }); this.setState({ visiableId: '' }); } }
                    onCancel={ () => { this.setState({ visiableId: '' }); } }
                  />
                }</span> }

            { this.props.permissionIds.includes('data.organization.mpuser.delete') && <span className="btn-opration" >
              <a
                href="javascript:;"
                onClick={ () => { this.setState({ visiableId: `${ record.id }delete` }); } }
              > { this.props.permissionIds.includes('data.organization.mpuser.delete') ? '解绑' : null } </a>
              { (this.state.visiableId === `${ record.id }delete`) &&
                <ConfirmPopout
                  strongText={ '确认删除该人员吗，删除后不可使用小程序功能' }
                  text={ ' ' }
                  styles={ { right: 0, width: '360px' } }
                  onOk={ () => { this.props.deleteMpuser({ id: record.id }); this.setState({ visiableId: '' }); } }
                  onCancel={ () => { this.setState({ visiableId: '' }); } }
                />
              }</span> }
          </div>
        ),
      },
    ];

    return tableHeadArr;
  };

  // 提交备注
  remarkSubmit = () => {
    const { currentRecord = {} } = this.state;
    let remarkerror = {};
    // if (!currentRecord.remark || (currentRecord.remark && currentRecord.remark.trim().length == 0)) { 
    //   remarkerror.error = '请输入备注';
    // } else
    if (currentRecord.remark && currentRecord.remark.trim().length > 100) {
      remarkerror.error = '最多100个字符';
    }
    if (remarkerror.error) {
      this.setState({remarkerror})
    } else {
      this.setState({ remarkerror: { error: '', touched: null } });
      currentRecord.remark = currentRecord.remark.trim();
      this.props.editMpuser({
        data: currentRecord, callback: () => { 
          this.setState({ showRemarkDialog: false });
      }});
    }
  }
  

  render () {
    const { mpuserList, loaddingMpuser, mpuserParam, perPageCountList, loadMpuser } = this.props;
    const { remarkerror, showRemarkDialog, currentRecord = {} } = this.state;
    let listStyle = mpuserList.length > 0 && !loaddingMpuser;
    return (
      <div
        className={`marginTop20 table-padding-bottom ${listStyle ? 'table-tr-height50' : 'table-tr-middle'}`}
      >

        <DataTable
          columns={this.getTableColumns ()}
          data={mpuserList}
          rowKey="id"
          rowClassName={record =>
            record.status == 'DISABLE' ? 'table-gray-row' : ''}
          style={{border: '1px solid #e2e2e2', borderBottom: 'none'}}
          messageRender={({data, error}) => {
            if (error != null) {
              return error.msg;
            }
            if (data == null || data.length === 0) {
              return (
                <span className="text-weak">
                 暂无数据
                </span>
              );
            }
          }}
          loading={loaddingMpuser}
        />
        {listStyle &&
          <div className="page-padding20">
            <Pager
              total={mpuserParam.totalElements}
              current={mpuserParam.page}
              perPageCount={mpuserParam.size}
              perPageCountList={perPageCountList}
              onPageChange={e => {
                loadMpuser({mpuserParam: {...mpuserParam, page: e}});
              }}
              onPerPageChange={e => {
                loadMpuser({mpuserParam: { ...mpuserParam, size: e, page: 0, }});
              }} // 每页大小
            />
          </div> }
          <Dialog 
            className="mpuser-dialog"
            style={{ width: '420px' }}
            // title="备注"
            visible={showRemarkDialog} 
            okText="确认"
            cancelText="取消"
          onOk={ () => { this.remarkSubmit(); }}
          onCancel={ () => { this.setState({ showRemarkDialog: false }); }}
            // onOk={handleSubmit(onSubmit)}
            // onCancel={handleDQrcodeModalCancel}
          >
            <FormGroupList>
              <FormGroup
              label="备注"
              // required
              control={ Textarea }
              value={ currentRecord.remark }
              onChange={ (e) => {
                this.setState({ currentRecord: {...currentRecord, remark: e.target.value} });
              } }
              text={ remarkerror.error || '最多100字符'}
              meta={ remarkerror}
              />
            </FormGroupList>
          </Dialog>

       
      </div>
    );
  }
}

export default MpUsers;
